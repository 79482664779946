<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group class="mb-3" label="File Type :" label-for="so">
            <b-form-select
              id="so"
              v-model="fileType"
              :options="fileTypeOptions"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group class="mb-3" label="Date :" label-for="daterange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker
                id="daterange"
                :start="startDate"
                :end="endDate"
                :disable="false"
                :autoUpdate="false"
                :timePicker="true"
                @picker="doDateFilter"
              >
              </range-picker>
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="secondary"
                  @click="resetDateFilter"
                  >Reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <b-button
          :disabled="stateButton"
          :style="buttonStyle"
          class="mb-2"
          @click="doFilter"
        >
          Apply Filter
        </b-button>
        <p class="mb-3" :style="{ color: 'rgba(115, 129, 143, 1)' }">
          Click button 'Apply Filter' to apply the filter
        </p>
      </b-col>
    </b-row>
    <div v-if="isLoadingTable">
      <b-skeleton-table
        :rows="10"
        :columns="11"
        :table-props="{ bordered: true }"
      >
      </b-skeleton-table>
    </div>
    <vuetable
      v-else
      ref="vuetable"
      :api-mode="false"
      :fields="fields"
      :per-page="limit"
      :data-manager="dataManager"
      pagination-path="pagination"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="created_at" slot-scope="prop">
        <span>{{ prop.rowData.created_at || "-" }}</span>
      </template>
      <template slot="file_type" slot-scope="prop">
        <span>{{ prop.rowData.transaction_type_name || "-" }}</span>
      </template>
      <template slot="user" slot-scope="prop">
        <span>{{ prop.rowData.partner_name || "-" }}</span>
      </template>
      <template slot="date_range" slot-scope="prop">
        <span>
          {{
            prop.rowData.start_date && prop.rowData.end_date
              ? `${prop.rowData.start_date} - ${prop.rowData.end_date}`
              : "-"
          }}
        </span>
      </template>
      <template slot="date_expired" slot-scope="prop">
        <span>{{ prop.rowData.expired_date || "-" }}</span>
      </template>
      <template slot="status" slot-scope="prop">
        <span :class="getStatusClass(prop.rowData.status_id)">
          {{ prop.rowData.status_name || "-" }}
        </span>
      </template>
      <template slot="action" slot-scope="prop">
        <div class="custom-actions-report">
          <button
            :class="
              prop.rowData.is_promotion === true
                ? 'btn btn-primary'
                : 'btn btn-success'
            "
            @click="handleDownloadReport(prop.rowData.id)"
            :disabled="idButton == prop.rowData.id && isLoadingButton"
            v-if="
              $can('report_transaction_download') && prop.rowData.status_id == 1
            "
          >
            <div
              class="sk-three-bounce custom-loading-bounce"
              v-if="idButton == prop.rowData.id && isLoadingButton"
            >
              <div class="sk-child sk-bounce1"></div>
              <div class="sk-child sk-bounce2"></div>
              <div class="sk-child sk-bounce3"></div>
            </div>
            <div
              class="d-flex align-items-center justify-content-center flex-nowrap"
              v-else
            >
              <i class="fa fa-download mr-2" aria-hidden="true"></i>
              Download
            </div>
          </button>
          <button
            class="btn btn-primary btn-log-report d-flex align-items-center justify-content-center"
            @click="
              handleLog(
                prop.rowData.id,
                `${prop.rowData.transaction_type_name} - ${prop.rowData.partner_name}`
              )
            "
          >
            <b-spinner
              class="custom-spinning"
              variant="light"
              label="Spinning"
              v-if="idButton == prop.rowData.id && isLoadingLog"
            ></b-spinner>
            <i class="fa fa-eye" aria-hidden="true" v-else></i>
          </button>
        </div>
      </template>
    </vuetable>
    <div
      class="vuetable-pagination ui basic segment grid"
      v-show="!isLoadingTable"
    >
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="current_page === 1" @click="doMovePage('first')">
            <i
              v-if="current_page === 1"
              class="fa fa-angle-double-left disabled"
            ></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="current_page === 1" @click="doMovePage('prev')">
            <i v-if="current_page === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.current_page }}</div>
          <button :disabled="data.length < 10" @click="doMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Modal Log -->
    <b-modal
      size="lg"
      v-model="isModalLog"
      @ok="handleModalLog"
      centered
      hide-footer
    >
      <template #modal-title>
        <div class="d-flex align-items-center justify-content-start">
          <h5 class="font-weight-bold mr-2 mb-0">
            Detail
          </h5>
          <h6 class="m-0">
            {{ titleModal }}
          </h6>
        </div>
      </template>
      <div>
        <b-card no-body class="mb-3">
          <b-card-header
            header-tag="header"
            class="py-1 px-3 bg-light d-flex align-items-center justify-content-between"
            role="tab"
          >
            <p class="font-weight-bold m-0">Filter Applied</p>
            <b-button
              class="btn btn-light btn-accordion"
              v-b-toggle="'accordion-filter'"
            >
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </b-button>
          </b-card-header>
          <b-collapse :id="'accordion-filter'" class="py-2 px-3">
            <div
              class="d-flex align-items-center justify-content-start"
              v-if="
                !dataLog.filter_applied || dataLog.filter_applied.length == 0
              "
            >
              <p>No filter applied</p>
            </div>
            <div v-else>
              <div
                class="d-flex align-items-center justify-content-between py-1"
                v-for="(item, index) in dataLog.filter_applied"
                :key="index"
                :class="{
                  'mb-2':
                    dataLog.filter_applied.length > 1 &&
                    index !== dataLog.filter_applied.length - 1
                }"
              >
                <p class="mb-1">{{ item.key || "-" }}</p>
                <p class="text-nowrap">{{ item.value || "-" }}</p>
              </div>
            </div>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-3">
          <b-card-header
            header-tag="header"
            class="py-1 px-3 bg-light d-flex align-items-center justify-content-between"
            role="tab"
          >
            <p class="font-weight-bold m-0">Log</p>
            <b-button
              class="btn btn-light btn-accordion"
              v-b-toggle="'accordion-logs'"
            >
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </b-button>
          </b-card-header>
          <b-collapse :id="'accordion-logs'" class="p-2">
            <div
              class="d-flex align-items-center justify-content-between p-2"
              v-for="(item, index) in dataLog.logs"
              :key="index"
              :class="{ 'mb-2': index !== dataLog.logs.length - 1 }"
            >
              <div class="d-flex align-items-start justify-content-center">
                <div
                  class="status-icon"
                  :class="{
                    'status-icon-success': [
                      'create-success',
                      'user-generate',
                      'user-download'
                    ].includes(item.type),
                    'status-icon-pending': item.type === 'cron-process',
                    'status-icon-failed': item.type === 'create-failed'
                  }"
                >
                  <i
                    :class="{
                      'fa fa-check': [
                        'create-success',
                        'user-generate'
                      ].includes(item.type),
                      'fa fa-clock-o': item.type === 'cron-process',
                      'fa fa-times': item.type === 'create-failed',
                      'fa fa-download': item.type === 'user-download'
                    }"
                  ></i>
                </div>
                <p class="ml-2 mb-1">{{ item.description || "-" }}</p>
              </div>
              <p class="text-nowrap">{{ item.created_at || "-" }}</p>
            </div>
          </b-collapse>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      count: 0,
      page: 1,
      current_page: 1,
      limit: 10,
      data: [],
      dataLog: {},
      startDate: "",
      endDate: "",
      fileType: "",
      fileTypeOptions: [
        { value: "", text: "Select file type" },
        { value: "gold", text: "Gold Transaction" },
        { value: "wallet", text: "Wallet Transaction" },
        { value: "partner", text: "Partner Transaction" }
      ],
      isLoading: false,
      isLoadingTable: false,
      isLoadingButton: false,
      isLoadingLog: false,
      isModalLog: false,
      idButton: null,
      paginationInfo: "",
      titleModal: "",
      errors: {
        code: "",
        message: "",
        status: ""
      },
      apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `golds`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sort: "created_at|desc",
      sortOrder: [
        {
          field: "created_at",
          sortField: "created_at",
          direction: "desc"
        }
      ],
      moreParams: {},
      fields: [
        {
          name: "created_at",
          sortField: "created_at",
          title: "Created Date"
        },
        {
          name: "file_type",
          title: "Type of File"
        },
        {
          name: "user",
          title: "User/Partner"
        },
        {
          name: "date_range",
          title: "Date Range Transaction"
        },
        {
          name: "date_expired",
          title: "Expired Date"
        },
        {
          name: "status",
          title: "Status"
        },
        {
          name: "action",
          title: "Action",
          titleClass: "center aligned"
        }
      ],
      data: [],
      currentPage: 0
    };
  },
  computed: {
    stateButton() {
      const filters =
        this.fileType === "" && this.startDate === "" && this.endDate === "";
      const params =
        Object.entries(this.moreParams).filter(([key, value]) => value)
          .length == 0;

      return filters && params;
    },
    buttonStyle() {
      return {
        background: !this.stateButton ? "#7565F6" : "#c8ced3",
        color: !this.stateButton ? "#FFFFFF" : "inherit"
      };
    }
  },
  watch: {
    data(newVal, oldVal) {
      if (
        this.$refs.vuetable &&
        typeof this.$refs.vuetable.refresh === "function"
      ) {
        this.$refs.vuetable.refresh();
      }
    }
  },
  methods: {
    onFetch() {
      const newParams = Object.entries(this.moreParams)
        .filter(([key, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      this.isLoadingTable = true;
      this.data = [];

      this.$http
        .get(
          `report-transaction?sort=${encodeURIComponent(this.sort)}&page=${
            this.page
          }&per_page=${this.limit}&${newParams}`
        )
        .then(response => {
          const datas = response.data.data;
          const datasCount = datas.length;
          const startIndex = (response.data.current_page - 1) * this.limit;
          const endIndex = Math.min(
            startIndex + this.limit,
            datasCount > 0 ? datasCount + startIndex : this.limit
          );

          this.data = datas;
          this.current_page = response.data.current_page;
          this.paginationInfo =
            datas.length > 0
              ? `Displaying ${startIndex + 1} to ${endIndex} of ${
                  response.data.total
                } items`
              : "No relevant data";

          this.isLoadingTable = false;
        })
        .catch(err => {
          this.isLoadingTable = false;
          this.handleLoadError(err);
        });
    },
    dataManager(sortOrder, pagination) {
      let local = this.data;

      if (local.length < 1) return;

      if (sortOrder.length > 0) {
        const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

        if (this.sort !== newSort) {
          this.sort = newSort;
          this.onFetch();
        }
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.limit);

      let from = pagination.from - 1;
      let to = from + this.limit;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onAction(action, data) {
      if (action == "log-view") {
        // ...
      } else {
        // ...
      }
    },
    doFilter() {
      this.$events.$emit("apply-filter");
    },
    handleLoadError(error) {
      const code = error.response.data.meta.code;
      const message = error.response.data.meta.message;

      this.errors.code = code;
      this.errors.message = message;
      this.errors.status = code;

      if (this.errors.code == 401) {
        this.$swal
          .fire(
            "Your session expired!",
            "Your session has expired. Please login again to access this page!",
            "error"
          )
          .then(() => {
            this.$router.push("/login");
          });
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      } else {
        this.$swal.fire(message, "error").then(() => {
          this.$router.reload();
        });
      }
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      // this.currentPage = paginationData.current_page;
      // this.data = paginationData.data;
      // this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onFilterSet() {
      this.page = 1;
      this.moreParams = {
        start_from: this.startDate,
        end_to: this.endDate,
        type: this.fileType
      };
      this.onFetch();
    },
    doDateFilter(value) {
      this.$events.fire("date-set", value);
    },
    onDateSet(value) {
      this.startDate = moment(value.startDate).format("YYYY-MM-DD HH:mm");
      this.endDate = moment(value.endDate).format("YYYY-MM-DD HH:mm");
    },
    doMovePage(value) {
      this.$events.$emit("movePage", value);
    },
    onMovePage(eventData) {
      if (eventData === "first") {
        this.page = 1;
        this.onFetch();
      } else if (eventData === "prev") {
        this.page--;
        this.onFetch();
      } else {
        this.page++;
        this.onFetch();
      }
    },
    getStatusClass(status) {
      if (!status && status !== 0) return "color-default";

      switch (status) {
        case 1:
          return "color-success";
        case 2:
          return "color-failed";
        case 0:
          return "color-pending";
        default:
          return "color-default";
      }
    },
    handleDownloadReport(id, val) {
      this.isLoadingButton = true;
      this.idButton = id;

      this.$http
        .get(`report-transaction/download/${id}`)
        .then(result => {
          const exportPath = result.data.data.file;
          window.location.href = exportPath;

          this.isLoadingButton = false;
          this.idButton = null;
        })
        .catch(error => {
          const err = error.response;

          if (err) {
            const code = err.data.meta.code;
            const message = err.data.meta.message;
            const status = err.data.meta.status;

            this.isLoadingButton = false;
            this.idButton = null;
            this.$swal.fire(status, message, "error");
          }
        });
    },
    handleModalLog() {
      const values = !this.isModalLog;
      this.isModalLog = values;

      if (!values) {
        this.titleModal = "";
        this.dataLog = {};
      }
    },
    handleLog(id, title) {
      this.isLoadingLog = true;
      this.idButton = id;
      this.titleModal = title;

      this.$http
        .get(`report-transaction/logs/${id}`)
        .then(result => {
          const data = result.data.data;
          this.dataLog = data;

          this.handleModalLog();

          this.isLoadingLog = false;
          this.idButton = null;
        })
        .catch(error => {
          const err = error.response;

          if (err) {
            const code = err.data.meta.code;
            const message = err.data.meta.message;
            const status = err.data.meta.status;

            this.isLoadingLog = false;
            this.idButton = null;
            this.$swal.fire(status, message, "error");
          }
        });
    }
  },
  mounted() {
    this.onFetch();
    this.$events.$on("apply-filter", () => this.onFilterSet());
    this.$events.$on("date-set", eventData => this.onDateSet(eventData));
    this.$events.$on("movePage", eventData => this.onMovePage(eventData));
  }
};
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-success {
  color: #35aa4b;
}
.color-failed {
  color: #cb211f;
}
.color-pending {
  color: #ff6900;
}
.color-default {
  color: #23282c;
}

.custom-actions-report {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
}

.text-center {
  text-align: center;
}

.btn-log-report {
  width: 42px;
  height: 28px;
}

.btn-accordion {
  background: transparent !important;
}
.btn-accordion:hover {
  border-color: transparent !important;
}

.custom-loading-bounce {
  width: 72px !important;
  height: 18px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.custom-loading-bounce .sk-child {
  width: 6px !important;
  height: 6px !important;
  background: #ffffff !important;
}
.custom-spinning {
  width: 12px;
  height: 12px;
  border-width: 2px;
}

.status-icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 10px;
}

.status-icon-success {
  color: #35aa4b;
  background: #f2f5f9;
}

.status-icon-pending {
  color: #ff6900;
  background: #f2f5f9;
}

.status-icon-failed {
  color: #cb211f;
  background: #fae9e9;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
